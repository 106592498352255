//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  props: ['location', 'pagelocation'],
  computed: {
    ...mapState({
      banner: ({ bannerStore: { banner } }) => banner,
      loadState: ({ loadstateStore: { loadState } }) => loadState,
    }),
  },
};
