//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { mapState } from 'vuex'
  import LazyHydrate from 'vue-lazy-hydration';
  import userChecks from '~/mixins/userChecks';
  export default {
    mixins: [userChecks],
    components: {
      LazyHydrate,
      recommendedVideos: () => import('~/components/home/recommended_videos.vue'),
      VideoOfTheWeek: () => import('~/components/home/VideoOfTheWeek.vue'),
      cams: () => import('~/components/home/live_cams.vue'),
      newestVideos: () => import('~/components/home/newest_videos.vue'),
      swiperFreeVideos: () => import('~/components/home/swiper_free_videos.vue'),
      categories: () => import('~/components/home/populair_categories.vue'),
      camHighlightMvH: () => import('~/components/home/cam_highlight_mvh.vue'),
      camHighlight: () => import('~/components/home/cam_highlight.vue'),
      models: () => import('~/components/home/populair_models.vue'),
      exclusiveVideos: () => import('~/components/home/exclusive_videos.vue'),
      privateItems: () => import('~/components/home/populair_private.vue'),
      spotlight: () => import('~/components/home/spotlight.vue'),
      shows: () => import('~/components/home/new_shows.vue'),
      epg: () => import('~/components/home/epg_now.vue'),
      topVideos: () => import('~/components/home/top_videos.vue'),
      plannedSexfilms: () => import('~/components/home/planned_sexfilms.vue'),
      about: () => import('~/components/about_section.vue'),
      randomVideos: () => import('~/components/home/random_videos.vue'),
      popunder: () => import('~/components/home/popunder.vue'),
      leaves: () => import('~/components/acties/leaves.vue'),
      htmlbanner: () => import('~/components/content/html5_banners/banner.vue'),
      DirectBanner: () => import('~/components/banner_direct.vue'),
      banner: () => import('~/components/banner.vue'),
      snow: () => import('~/components/acties/snowflakes.vue')
    },
    async fetch() {
      await Promise.all([ 
        this.$store.dispatch('pagesStore/getPage', 'home'),
        this.$store.dispatch('bannerStore/getBanner', 'HomeDirect'),
        this.$store.dispatch('bannerStore/getBanner', 'Home')
      ])
    },
    computed: {
      ...mapState({
        pages: ({ pagesStore: {pages} }) => pages,
        cam_highlight_mvh: ({ camStore: {cam_highlight_mvh} }) => cam_highlight_mvh
      }),
    },
    head() {
      return {
        title: this.pages.homepage ? this.pages.homepage.seo.title : 'Meiden van Holland | Sexfilms & Webcamsex met geile meiden',
        meta: [
          { hid: 'title', name: 'title', content: this.pages.homepage ? this.pages.homepage.seo.title : 'Meiden van Holland | Sexfilms & Webcamsex met geile meiden' },
          { hid: 'og:title', name: 'og:title', content: this.pages.homepage ? this.pages.homepage.seo.title : 'Meiden van Holland | Sexfilms & Webcamsex met geile meiden' },
          { hid: 'description', name: 'description', content: this.pages.homepage ? this.pages.homepage.seo.description : 'Van tiener tot milf. Meiden van Holland is de sexzender voor geile amateur sexfilms en webcamsex met Hollandse meiden.'},
          { hid: 'og:description', name: 'og:description', content: this.pages.homepage ? this.pages.homepage.seo.description : 'Van tiener tot milf. Meiden van Holland is de sexzender voor geile amateur sexfilms en webcamsex met Hollandse meiden.' },
          { hid: 'og:url', name: 'og:url', content: process.env.FE_URL },
          { hid: 'keywords', name: 'keywords', content: this.pages.homepage ? this.pages.homepage.seo.keywords : 'Meiden van Holland, sex, Hollandse sex, amateur sex, poldersex, Nederlandse sex'},
        ]
      }
    },
    beforeRouteEnter(to, from, next) {
      if (to.query.confirmcode) {
        next(vm => {
          vm.confirmUser(to.query.confirmcode);
        })
      } else if (to.query.resetcode) {
        next(vm => {
          vm.resetPswd(to.query.resetcode);
        })
      } else if (to.query.logincode) {
        next(vm => {
          vm.loginAsUser(to.query.logincode);
        })
      } else if (to.query.ended) {
        next(vm => {
          vm.ended(to.query.ended);
        })
      } else if (to.query.message && to.query.message === 'expired') {
        next(vm => {
          var alertObj = {
            type: 'info',
            message: 'Je bent uitgelogd omdat je sessie is verlopen. Klik op deze melding om opnieuw in te loggen.',
            href: '/account/inloggen'
          };
          vm.$store.commit('alertStore/setAlert', alertObj);
        })
      } else {
        next();
      }
    },
    methods: {
     async confirmUser(confirmCode) {
      var self = this;
      if (this.$auth.loggedIn) {
        this.$auth.logout();
      }
      let data = {
        confirmation_code: confirmCode,
      };
      await this.$axios.post(this.$config.apiUrl + '/api/mvh/user-confirm', data, {
      }).then(function (response) {
        return self.login(response.data.authorisation.token, 'confirm');
      }).catch((error) => {
        var alertObj = [];
        alertObj.type = 'error';
        alertObj.message = 'Er is iets mis gegaan. Je account is niet geactiveerd. Klik op deze melding om contact op te nemen.'
        alertObj.href = '/info/contact';
        self.$store.commit('alertStore/setAlert', alertObj);
      })
    },
    async resetPswd(confirmCode) {
      var self = this;
      if (this.$auth.loggedIn) {
        this.$auth.logout();
      }
      this.$router.push({
        path: '/account/wachtwoord-herstellen',
        query: { confirmation_code: confirmCode }
      })
    },
    async loginAsUser(confirmCode) {
      var self = this;
      if (this.$auth.loggedIn) {
        this.$auth.logout();
      }
      let data = {
        confirmation_code: confirmCode,
      };
      await this.$axios.post(this.$config.apiUrl + '/api/mvh/login-as-user', data, {
      }).then(function (response) {
        self.$auth.setUserToken(response.data.authorisation.token, response.data.authorisation.token).then(function () {
          self.$router.push({
            path: '/account/gegevens-voorkeuren'
          })
          var alertObj = [];
          alertObj.type = 'info';
          alertObj.message = 'Je bent nu ingelogd als user: ' + response.data.user.email;
          return self.$store.commit('alertStore/setAlert', alertObj);
        })
      }).catch((error) => {
        var alertObj = [];
        alertObj.type = 'error';
        alertObj.message = 'Er is iets mis gegaan. Je account is niet geactiveerd. Klik op deze melding om contact op te nemen.'
        alertObj.href = '/info/contact';
        self.$store.commit('alertStore/setAlert', alertObj);
      })
    },      
    login(token, todo) {
      var self = this;
      var refreshToken = token;
      if (todo == 'resetpswd') {
        this.$auth.setUserToken(token, refreshToken).then(function () {
          self.$router.push({
            path: '/account/gegevens-voorkeuren?resetpswd'
          })
          var alertObj = [];
          alertObj.type = 'info';
          alertObj.message = 'We hebben je wachtwoord gereset. Stel hierboven een nieuw wachtwoord in zodat je daar voortaan mee kunt inloggen.';
          self.$store.commit('alertStore/setAlert', alertObj);
        })
      } else {
        this.$auth.setUserToken(token, refreshToken).then(function () {
          var alertObj = [];
          alertObj.type = 'succes';
          alertObj.message = 'Jouw registratie was een succes. We hebben je meteen ingelogd. Welkom bij ' + self.$config.appTitle + '!';
          self.$store.commit('alertStore/setAlert', alertObj);
        })
      }
    },
    ended(actie) {
      var alertObj = {};
      alertObj.type = 'info';
      alertObj.message = 'De ' + actie + '-actie is helaas afgelopen.';
      this.$store.commit('alertStore/setAlert', alertObj);
    }
  }
}
